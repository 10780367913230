<script>
import languages from '@/data/languages.json'
import contents from '@/data/contents.json'
import { useToast } from "vue-toastification";

import UIkit from 'uikit';

export default {
  name: "Form",
  data: () => ({
    toast: useToast(),
    languages: languages,
    contents: contents,
    selectedLanguage: null,
    selectedText: null,
    customerName: '',
    customerGender: '',
    reference: '',
    textConstruct: '',
  }),
  mounted() {
    const form = document.getElementById('setup');
    form.addEventListener("change", this.updatePreview);

    this.selectedLanguage = this.languages['de'].tag;
    this.selectedText = this.contents['reachability_sms'].id;
  },
  methods: {
    updatePreview() {
      if (this.selectedLanguage === '') {
        return;
      }
      if (this.selectedText === '') {
        return;
      }
      let text = contents[this.selectedText].texts[this.selectedLanguage];
      let refBlock = '';
      if(this.customerGender !== '' && this.customerName.trim() !== ''){
        text = text.replace(/{{SALUTION}}/g, contents['salutation'][this.customerGender][this.selectedLanguage]);
        text = text.replace(/{{NAME}}/g, this.customerName);
      }else{
        text = text.replace(/{{SALUTION}}/g, '');
        text = text.replace(/{{NAME}}/g, '');
      }

      if(this.reference.trim().length) {
        refBlock = " "  + contents[this.selectedText].blocks.refblock[this.selectedLanguage].replace(/{{REFERENCE}}/g, this.reference);
      }
      text = text.replace(/{{REFBLOCK}}/g, refBlock);
      text = text.replace(/\n/g, '<br>');
      this.textConstruct = text;
    },
    copyToClipboard() {
      const copyText = this.textConstruct.replace(/<br>/g, '\n')
      navigator.clipboard.writeText(copyText).then(
          () => {
            this.toast.success("Copied to clipboard");
          },
          () => {
            this.toast.error("Unable to copy to clipboard");
          },
      );
    },
  },
}
</script>

<template>
  <div>
    <form id="setup">
      <div class="uk-margin">
        <label for="language">Language</label>
        <select class="uk-select" name="language" v-model="selectedLanguage">
          <option v-for="language in languages" :key="language.tag" :value="language.tag">
            {{ language.name }}
          </option>
        </select>
      </div>
      <div class="uk-margin">
        <label for="contentSelect">Content</label>
        <select class="uk-select" name="contentSelect" v-model="selectedText">
          <option v-for="content in contents" :key="content.id" :value="content.id">
            {{ content.name }}
          </option>
        </select>
      </div>
      <div class="uk-margin">
        <div class="uk-child-width-1-2@s" uk-grid>
          <div>
            <label for="name">Customer Gender</label>
            <select class="uk-select" name="gender" v-model="customerGender">
              <option value="">not set</option>
              <option value="male">male</option>
              <option value="female">female</option>
            </select>
          </div>
          <div>
            <label for="name">Customer Name</label>
            <input class="uk-input" name="name" v-model="customerName"/>
          </div>
        </div>

      </div>
      <div class="uk-margin">
        <label for="reference">Reference</label>
        <input class="uk-input" name="reference" v-model="reference"/>
      </div>
    </form>
    <button class="uk-button uk-button-small uk-button-primary uk-width-1-1" @click="updatePreview">Generate</button>
  </div>

  <div v-if="textConstruct"
       class="uk-margin uk-background-muted uk-border-rounded uk-padding-small uk-position-relative">
    <div class="uk-position-top-right" style="padding:6px;">
      <a href="#" uk-icon="icon:copy" @click="copyToClipboard"></a>
    </div>
    <p v-html="textConstruct" class="uk-text-left"></p>
  </div>

</template>

<style scoped lang="less">

</style>

