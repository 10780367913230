<script>
export default {
  name: "Navbar"
}
</script>

<template>
  <nav class="uk-navbar-container">
    <div uk-navbar>
      <div class="uk-container">
        <div class="uk-navbar-left">
          <ul class="uk-navbar-nav">
            <li class="uk-active">
              <router-link to="/">Generator</router-link>
            </li>
            <li>
              <router-link to="/about">About</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="less">

</style>