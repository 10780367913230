<template>
  <Navbar />
  <div class="uk-section">
    <div class="uk-container">
      <router-view/>
    </div>
  </div>

</template>

<script>

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Navbar from "@/components/Navbar.vue";

UIkit.use(Icons);
export default {name: 'App',
  components: {Navbar},
  mounted() {
    document.title = "SMS Generator by NXD";
  }
};

</script>

<style lang="less">

@import "../node_modules/uikit/src/less/uikit.less";
@import "./assets/less/theme.less";

</style>
